


























































































import KDialog from '@/@core/components/dialogs/KDialog.vue';
import useModelValue from '@/@core/helpers/modelValue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  computed,
  defineComponent,
  PropType,
  ref,
  unref
} from '@vue/composition-api';
import NoteForm from './NoteForm.vue';
import { useNoteStore } from '../services/store';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import { useIndustryStore } from '@/modules/industry/services/store';
import { toNormalDate } from '@/@core/helpers/formatter';

export default defineComponent({
  components: { KDialog, NoteForm },
  name: 'NotePanel',
  props: {
    notes: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    industry: {
      type: Object as PropType<any>,
      default: () => {}
    }
  },
  emits: ['added:note'],
  setup(props, { emit, attrs }) {
    const store = useNoteStore();
    const uiStore = useUiStateStore();
    const industryStore = useIndustryStore();
    const showPanel = useModelValue({ emit, attrs });
    const reRenderCount = ref(0);
    const isLoading = ref(false);
    const showCreateDialog = ref(false);
    const formData = ref({
      title: '',
      description: ''
    });

    const sortedNotes = computed(
      () =>
        props?.notes?.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() -
            new Date(b.createdAt).getTime()
        ) || []
    );

    const resetFormData = () => {
      formData.value = {
        title: '',
        description: ''
      };
    };

    const onSubmit = async () => {
      isLoading.value = true;

      try {
        // @ts-ignore
        const industryId = unref(props.industry).id;
        // @ts-ignore
        formData.value.industry = industryId;

        await store.postNote(formData);

        emit('added:note');

        uiStore.createSnackbar({
          message: `Catatan berhasil ditambahkan.`
        });

        reRenderCount.value++;

        showCreateDialog.value = false;

        resetFormData();
      } catch (error: any) {
        uiStore.createSnackbar({
          message: `Terjadi kesalahan ${error.message}.`,
          timeout: 10000,
          color: 'error'
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      showPanel,
      isMobile,
      showCreateDialog,
      formData,
      onSubmit,
      isLoading,
      reRenderCount,
      resetFormData,
      sortedNotes,
      toNormalDate
    };
  }
});
